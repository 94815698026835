import React from "react";
import Map from "../components/Map";

function Europe() {
  return (
    <div>
      <div className="p-auto m-auto justify-center items-center">
        <Map title="Endangered Species in Australia" className="w-96 h-96" filename='./Europe_filtered.json' center_x = "50.2011" center_y = "13.6602" zoom="4"/>
      </div>
    </div>
  );
}

export default Europe;
