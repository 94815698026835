import React from "react";
import Map from "../components/Map";

function SouthAmerica() {
  return (
    <div>
      <div className="p-auto m-auto justify-center items-center">
        <Map title="Endangered Species in South America" className="w-96 h-96" filename='./South_America_filtered.json' center_x = "-15.7861" center_y = "-56.6482" zoom="4"/>
      </div>
    </div>
  );
}

export default SouthAmerica;
