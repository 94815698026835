import React from "react";
import Map from "../components/Map";

function Africa() {
  return (
    <div>
      <div className="p-auto m-auto justify-center items-center">
        <Map title="Endangered Species in Africa" className="w-96 h-96" filename='./Africa_filtered.json' center_x = "2.3942" center_y = "16.4006" zoom="4"/>
      </div>
    </div>
  );
}

export default Africa;
