import React from "react";
import Map from "../components/Map";

function Australia() {
  return (
    <div>
      <div className="p-auto m-auto justify-center items-center">
        <Map title="Endangered Species in Australia" className="w-96 h-96" filename='./Australia_filtered.json' center_x = "-25.0105" center_y = "133.5975" zoom="4"/>
      </div>
    </div>
  );
}

export default Australia;
