import React from "react";
import Map from "../components/Map";

function Asia() {
  return (
    <div>

          <Map title="Endangered Species in Asia" className="w-96 h-96" filename='./Asia_filtered.json' center_x="35.7465" center_y="85.6046" zoom="4" />
    </div>
  );
}

export default Asia;
