import React from "react";

const InfoPanel = ({ properties }) => {
  console.log(properties);

  const originMapping = {
    1: 'Native',
    2: 'Reintroduced',
    3: 'Introduced',
    4: 'Vagrant',
    5: 'Origin Uncertain',
    6: 'Assisted Colonisation'
  };

  const displayOrigin = originMapping[properties.origin] || '';


  return (
    <div className="info-panel">
      <h1 className="text-white font-bold">KNOW MORE ABOUT THE ENDANGERED SPECIES</h1>
      <br/>
      <table className="w-full text-white table-auto border border-slate-600 border-collapse border-solid">
        <tbody>
          {properties.partnerurl && (
            <tr>
              <td className="font-bold pr-4 border border-slate-600">Donate to Partner:</td>
              <td class="border border-slate-600 pl-5"><a href={properties.partnerurl} target="_blank" rel="noopener noreferrer"><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 mb-4">Donate Now</button></a></td>
            </tr>
          )}
          {properties.imageurl && (
            <tr>
              <td className="font-bold pr-4 border border-slate-600">Animal Image:</td>
              <td class="border border-slate-600 pl-5 mx-auto"><img src={properties.imageurl} alt={properties.sci_name} className="w-40 h-40 object-cover mt-4 mb-4" /></td>
            </tr>
          )}
          <tr>
            <td className="font-bold pr-4 border border-slate-600">Common Name:</td>
            <td class="border border-slate-600 pl-5">{properties.common_name}</td>
          </tr>
          <tr>
            <td className="font-bold pr-4 border border-slate-600">Scientific Name:</td>
            <td class="border border-slate-600 pl-5">{properties.sci_name}</td>
          </tr>

          <tr>
            <td className="font-bold pr-4 border border-slate-600">Genus:</td>
            <td class="border border-slate-600 pl-5">{properties.genus}</td>
          </tr>
          <tr>
            <td className="font-bold pr-4 border border-slate-600">Phylum:</td>
            <td class="border border-slate-600 pl-5">{properties.phylum}</td>
          </tr>
          <tr>
            <td className="font-bold pr-4 border border-slate-600">Class:</td>
            <td class="border border-slate-600 pl-5">{properties.class}</td>
          </tr>
          <tr>
            <td className="font-bold pr-4 border border-slate-600">Family:</td>
            <td class="border border-slate-600 pl-5">{properties.family}</td>
          </tr>
          <tr>
            <td className="font-bold pr-4 border border-slate-600">Kingdom:</td>
            <td class="border border-slate-600 pl-5">{properties.kingdom}</td>
          </tr>
          <tr>
            <td className="font-bold pr-4 border border-slate-600">Status:</td>
            <td class="border border-slate-600 pl-5">{properties.category === 'CR' ? 'Critically Endangered' : 'Endangered'}</td>
          </tr>
          <tr>
            <td className="font-bold pr-4 border border-slate-600">Year of Data:</td>
            <td class="border border-slate-600 pl-5">{properties.yrcompiled}</td>
          </tr>
          <tr>
            <td className="font-bold pr-4 border border-slate-600">Origin:</td>
            <td class="border border-slate-600 pl-5">{displayOrigin}</td>
          </tr>

        </tbody>
      </table>
    </div>
  );
};

export default InfoPanel;
