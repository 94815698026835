import React, { useState } from 'react';

function NewNavbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showRegionsDropdown, setShowRegionsDropdown] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    setShowRegionsDropdown(false); // Close regions dropdown when toggling menu
  };

  const toggleRegionsDropdown = () => {
    setShowRegionsDropdown(!showRegionsDropdown);
  };

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <a href="/" className="flex items-center">
          <img src="./logo.png" alt="Logo" className="w-10 h-10 mr-2" />
          <h1 className="text-white text-2xl font-bold">Species Saver</h1>
        </a>

        {/* Menu Items */}
        <div className="hidden sm:flex space-x-4">
          <a href="/top50" className="text-white">Top 50</a>
          <div className="relative inline-block">
            <button
              onClick={toggleRegionsDropdown}
              className="text-white hover:bg-gray-700 focus:outline-none"
            >
              Regions
            </button>
            {showRegionsDropdown && (
              <div className="absolute mt-2 bg-gray-900 text-white p-2 rounded shadow-lg">
                <a href="/Asia" className="block py-1">Asia</a>
                <a href="/Africa" className="block py-1">Africa</a>
                <a href="/Australia" className="block py-1">Australia</a>
                <a href="/Europe" className="block py-1">Europe</a>
                <a href="/NorthAmerica" className="block py-1">North America</a>
                <a href="/SouthAmerica" className="block py-1">South America</a>
              </div>
            )}
          </div>
          <a href="/About" className="text-white">About</a>
          <a href="https://join.wwfindia.org/?source=DFR-Google&gclid=CjwKCAjw-KipBhBtEiwAWjgwrK-dxOVUUcReDdp2F_rI9Wzd8uhSGCe8sYncavtyFWkAz5MPikFMKBoC8kkQAvD_BwE" target="_blank" rel="noopener noreferrer" className="bg-yellow-500 text-white px-3 py-1 rounded-full">Donate Now</a>
        </div>

        {/* Hamburger Menu Icon for Mobile */}
        <div className="block sm:hidden">
          <button
            onClick={toggleMobileMenu}
            className="text-white focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {showMobileMenu ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu Overlay */}
      {showMobileMenu && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-start justify-end p-4">
          <div className="bg-gray-800 p-4 rounded shadow-lg">
            <button
              onClick={toggleMobileMenu}
              className="text-white focus:outline-none mb-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <a href="/top50" className="text-white block mb-2">Top 50</a>
            <div className="relative mb-2">
              <button
                onClick={toggleRegionsDropdown}
                className="text-white hover:bg-gray-700 focus:outline-none block"
              >
                Regions
              </button>
              {showRegionsDropdown && (
                <div className="bg-gray-900 text-white p-2 rounded shadow-lg mt-2">
                  <a href="/Asia" className="block py-1">Asia</a>
                  <a href="/Africa" className="block py-1">Africa</a>
                  <a href="/Australia" className="block py-1">Australia</a>
                  <a href="/Europe" className="block py-1">Europe</a>
                  <a href="/NorthAmerica" className="block py-1">North America</a>
                  <a href="/SouthAmerica" className="block py-1">South America</a>
                </div>
              )}
            </div>
            <a href="/About" className="text-white block mb-2">About</a>
            <a href="https://join.wwfindia.org/?source=DFR-Google&gclid=CjwKCAjw-KipBhBtEiwAWjgwrK-dxOVUUcReDdp2F_rI9Wzd8uhSGCe8sYncavtyFWkAz5MPikFMKBoC8kkQAvD_BwE" target="_blank" rel="noopener noreferrer" className="bg-yellow-500 text-white px-3 py-1 rounded-full">Donate Now</a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NewNavbar;
