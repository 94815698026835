import React from "react";
import Map from "../components/Map";

function NorthAmerica() {
  return (
    <div>
      <div className="p-auto m-auto justify-center items-center">
        <Map title="Endangered Species in North America" className="w-96 h-96" filename='./North_America_filtered.json' center_x = "41.1545" center_y = "-99.0417" zoom="4"/>
      </div>
    </div>
  );
}

export default NorthAmerica;
