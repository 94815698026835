import React, { useState, useEffect } from "react";
import {
  MapContainer,
  LayersControl,
  TileLayer,
  GeoJSON,
} from "react-leaflet";
import InfoPanel from "./InfoPanel";
import Footer from '../components/Footer.jsx';

const Map = (props) => {

  function displayErrorMessage(message) {
    alert(message);
  }
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [mapdata, setMapData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(props.filename);
        const response = await fetch(props.filename, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonData = await response.json();

        setMapData(jsonData);
        setLoading(false); // Set loading to false after data is loaded


      } catch (error) {
        console.error('Error loading JSON file:', error);
        displayErrorMessage('Error loading the map. Please try again later.');
        setLoading(false); // Set loading to false on error

      }
    };

    fetchData();
  }, [props.filename]);

  const handleClick = (e) => {
    // Access the clicked layer via e.target
    const clickedLayer = e.target;
    var newStyle = {
      fillColor: 'blue',
      color: 'white',
      weight: 2,
      opacity: 1,
      fillOpacity: 0.7
    };
    console.log('Rest Layer clicked:', clickedLayer);
    clickedLayer.setStyle(newStyle);
    setSelectedFeature(clickedLayer.feature.properties);

    // Perform actions with the clicked layer
  };

  // Define style function to set colors based on category
  const getFeatureStyle = (feature) => {
    const category = feature.properties.category;
    let fillColor = category === 'EN' ? 'orange' : 'red';

    return {
      fillColor,
      color: 'white',
      weight: 2,
      opacity: 1,
      fillOpacity: 0.7
    };
  };


  return (
    <div>
      {loading ? (

        // Show loading animation while mapData is being loaded
        <div className="loading-animation text-white flex flex-col justify-center items-center h-screen">
          <div className="animate-spin rounded-full border-t-2 border-b-2 border-white h-16 w-16"></div>
          <br/>
          <h1>Loading the map. Please wait...</h1>
          {console.log("Loading is true")} {/* Add this line */}

        </div>
      ) :
        (mapdata && (

          <div className="flex justify-center pl-10 pr-10">
            <div className="flex flex-col">

              <div><h1 className="font-bold text-blue-500 underline mb-4 mt-4 uppercase">{props.title}</h1></div>
              <div>
                <p className="text-left text-white">
                  To know more about the endangered species, <span style={{ fontWeight: 'bold', color: 'yellow' }}>please click on the orange or red polygon.</span><br />
                  Once you click, you can see the details displayed below. <br />Wherever there is a donation option available, you will also see a <span style={{ fontWeight: 'bold', color: 'yellow' }}>'Donate Now'</span> button.<br /> Please click to visit website of partner who works on the convservation of the species and donate to the cause.
                </p>
              </div>
              <br />

              <div className="flex flex-col md:flex-row">

              <div className="max-w-screen-lg w-full p-4">
                  <MapContainer
                    center={[parseInt(props.center_x), parseInt(props.center_y)]}
                    zoom={parseInt(props.zoom)}
                    className="w-full h-64 md:h-96 lg:h-128"
                    >
                    <LayersControl position="topright">
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      <GeoJSON data={mapdata} style={getFeatureStyle} onEachFeature={(feature, layer) => {
                        layer.on({
                          click: handleClick, // Attach click event handler to the layer
                        });
                      }} />
                    </LayersControl>
                  </MapContainer>
                </div>
                <div className="mt-4 md:mt-0 md:pl-4 w-full">
                  {selectedFeature && <InfoPanel properties={selectedFeature} />}
                </div>
              </div>
            </div>
          </div >)

        )}
        <Footer/>
    </div>
  );
};

export default Map;