import React from "react";
 
function About() {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 my-8">
      <div className="max-w-md mx-auto md:mx-0">
        <img
          src="../swami_image.jpg" // Replace with the actual image URL
          alt="Swaminathan Gowrishankar"
          className="pl-10 object-cover mx-auto md:mx-0"
        />
      </div>
      <div className="max-w-md mx-auto text-center md:text-left text-white">
        <h2 className="text-3xl font-bold mb-4">Meet Swaminathan Gowri Shankar</h2>
        <p className="text-lg mb-4">
          Swaminathan Gowri Shankar a.k.a Swami, a passionate 12th-grade student at TISB, discovered his love for wildlife during a transformative safari trip in Kenya. This experience ignited a fervent curiosity and dedication to preserving our planet's diverse species.
        </p>
        <p className="text-lg mb-4">
          Over the past year, Swami has delved into extensive research, identifying various data sources and honing his skills in React programming, Python, and data analytics. His goal: to create Species Saver, a platform designed to make a meaningful impact on wildlife conservation through technology.
        </p>
        <p className="text-lg">
          Swami envisions a future where technology plays a pivotal role in safeguarding endangered species and their habitats. With a strong passion for computer science, he plans to pursue it as a major, combining his love for programming with his dedication to wildlife conservation.
        </p>
      </div>
    </div>
  );
}

export default About;
