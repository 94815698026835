import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white p-2 text-center text-sm flex-col">
            <div className="flex-col">
                <div className="mb-2">Map data © IUCN (International Union for Conservation of Nature) | <a href="https://www.iucn.org/" className="text-blue-300">Learn more about IUCN</a></div>
              
            </div>
            <p className="mt-2">&copy; 2023 Swaminathan Gowrishankar</p>
        </footer>
    );
};

export default Footer;