import React from "react";
import Map from "../components/Map";

function Top50() {
  return (
    <div>
      <div className="p-auto m-auto justify-center items-center">
        <Map title="Top 50 endangered species across the world" className="w-96 h-96" filename='./Top50Endangered.json' center_x = "9.15" center_y = "9.15" zoom="2"/>
      </div>
    </div>
  );
}

export default Top50;
