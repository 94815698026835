import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Home";
import Top50 from "./Top50";
import Asia from "./Asia";
import Africa from "./Africa";
import Australia from "./Australia";
import Europe from "./Europe";
import NorthAmerica from "./NorthAmerica";
import SouthAmerica from "./SouthAmerica";
import NewNavbar from "../components/ExampleHeader"
import About from "./About";

function App() {
  return (
    <Router>
      <div>
        <NewNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/top50" element={<Top50 />} />
          <Route path="/asia" element={<Asia />} />
          <Route path="/africa" element={<Africa />} />
          <Route path="/australia" element={<Australia />} />
          <Route path="/europe" element={<Europe />} />
          <Route path="/northamerica" element={<NorthAmerica />} />
          <Route path="/southamerica" element={<SouthAmerica />} />
          <Route path="/about" element={<About />} />

        </Routes>
      </div>
    </Router>
  );
}
ReactDOM.render(<App />, document.getElementById("root"));

export default App;
