import React from "react";
import Footer from "../components/Footer";
function Home() {
  return (
    <div>
      <section class="bg-balck text-white">
        <div class="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
          <div class="mx-auto max-w-3xl text-center">
            <h1 class="bg-gradient-to-r from-white to-red-900 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl p-3">
              More than 9000+ Species are Endangered Today
            </h1>

            <p class="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
              In today's world, the plight of our planet's biodiversity is more critical than ever. Shockingly, over 9000 species find themselves teetering on the brink of extinction, facing threats from habitat loss, climate change, and human activities. These endangered species represent a crucial part of Earth's delicate ecological balance, each contributing to the intricate web of life that sustains us all. It is our collective responsibility to take action, to protect and preserve these invaluable members of our global community. Together, we can work towards a future where every species thrives and where our natural world continues to inspire and captivate generations to come. 
            </p>

            <div class="mt-8 flex flex-wrap justify-center gap-4">
              <a
                class="block w-full rounded border border-black bg-white px-12 py-3 text-sm font-medium text-black focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
                href="/top50"
              >
                Get Started
              </a>

              <a
                class="block w-full rounded border border-white px-12 py-3 text-sm font-medium text-white focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
                href="/about"
              >
                About
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}

export default Home;
